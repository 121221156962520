export function getCleanedDomainName() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    let domainName = url.hostname;
  
    // Remove "www." and ".com" if present
    domainName = domainName.replace(/^www\./i, '').replace(/\.com$/i, '');
  
    // Remove "staging" if present
    domainName = domainName.replace(/staging\./i, '');

    // Comment this for production and uncomment the above variable
    //domainName = 'shirdisaitemple';
    //domainName = 'shreekarveernivasini';
    //domainName = 'indiatemple.net';
  
    return domainName;
}
  