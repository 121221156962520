import React, { useState, useEffect } from 'react';
import { getCleanedDomainName } from './urlFetch';
import { GroupHeader } from './GroupHeader';
import { HeaderIndividual } from './HeaderIndividual';

function Header() {

  // Domain details Fetch
  const [domainName, setDomainName] = useState('');

  // Fetching the domain name
  useEffect(() => {
    setDomainName(getCleanedDomainName());
  }, []);

  console.log('Cleaned Domain Name:', domainName);

  const [micrositeData, setMicrositeData] = useState([]);

  useEffect(() => {
    // Fetch microsite data from the API
    const groupCheck = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/all?cat=group`;
    fetch(groupCheck)
      .then(response => response.json())
      .then(data => setMicrositeData(data))
      .catch(error => console.error('Error fetching microsite data:', error));
  }, []);

  // Check if the domain matches any item in micrositeData
  const isGroupMicrosite = micrositeData.some(item => domainName.includes(item.domain));

  return (
    <>
      {isGroupMicrosite ? (
        <GroupHeader />
      ) : (
        <HeaderIndividual />
      )}
    </>
  );
}

export default Header;