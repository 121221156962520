import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SubscribeRibbon from './SubscribeRibbon';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { getCleanedDomainName } from './urlFetch';

function Product() {      

    // Domain details Fetch
    const [domainName, setDomainName] = useState('');

    // Fetching the domain name
    useEffect(() => {
        setDomainName(getCleanedDomainName());
    }, []);

    console.log('Cleaned Domain Name:', domainName);

    const [microData, setMicroData] = useState({
        id: '',
        name: '',
        domain: '',
        p1: '',
        p2: '',
        p3: '',
        place: '',
    });
    const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}microsite_data/${domainName}`;

    useEffect(() => {
        if(domainName){
        console.log('API URL:', apiUrl);

        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
            console.log('API Response:', data);
            setMicroData(data[0]); // Assuming data is an array with a single item
            })
            .catch(error => console.error('API Error:', error));
        }
    }, [domainName, apiUrl]);

    //console.log('Name:', microData.name);
    //console.log('Domain:', microData.domain);
    console.log('P1:', microData.p1);
    console.log('P2:', microData.p2);
    console.log('P3:', microData.p3);


    const [swiperData, setSwiperData] = useState([]);
    
    useEffect(() => {
        if(microData.id){
        const microDataUrl = `${process.env.REACT_APP_API_ENDPOINT}home_widget_ids?cat=temple_slides&id=${microData.id}`;
        console.log('microDataUrl:', microDataUrl);
        // Replace with your API URL
        fetch(microDataUrl)
            .then((response) => response.json())
            .then((data) => setSwiperData(data))
            .catch((error) => console.error('Error fetching data:', error));
        }
    }, [microData.id]);



  const { id, title } = useParams();
  const [productData, setProductData] = useState({});
  const { 
    product_name
    ,weight
    ,short_description
    ,product_description
    ,category
    ,material
    ,category_name
    ,height
    ,width
    ,sku
    ,url
    ,meta_description
    ,price
    ,featured
    ,qty_available
    ,display_status
   } = productData;

  const productsAPIURL = `${process.env.REACT_APP_API_ENDPOINT}product/${id}`;
  console.log("productsAPIURL", productsAPIURL);

  useEffect(() => {
    fetch(productsAPIURL)
      .then(response => response.json())
      .then(data => setProductData(data))
      .catch(error => console.error(error));
  }, []);  


  const [thumbImagesGallery, setThumbImagesGallery] = useState([]);
  useEffect(() => {
    const imageAPIURLGallery =  `${process.env.REACT_APP_API_ENDPOINT}image_retrieve?id=${id}&image_type=gallery&site_category=product`;
    console.log("imageAPIURLGallery: ", imageAPIURLGallery);
    fetch(imageAPIURLGallery)
      .then(response => response.json())
      .then(data => setThumbImagesGallery(data))
      .catch(errorGallery => console.errorGallery(errorGallery));
  }, []);

  const images = thumbImagesGallery.map((image) => ({
    original: `${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${image.image_src}`,
    thumbnail: `${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${image.image_src}`,
  }));  

    
    const [similarProducts, setSimilarProducts] = useState([]);
    useEffect(() => {
        const similarProductsUrl = `${process.env.REACT_APP_API_ENDPOINT}similar_items?city_id=${category}&item=products&neglect=${id}`;
        console.log("similarProductsUrl: ", similarProductsUrl);
        fetch(similarProductsUrl)
          .then(response => response.json())
          .then(data => setSimilarProducts(data))
          .catch(error => console.error(error));
    }, [id, category]);
    
    //console.log("tourPlans: ", tourPlans);

    const [materialName, setMaterialName] = useState([]);

    useEffect(() => {
    if (material) {
        const materialsUrl = `${process.env.REACT_APP_API_ENDPOINT}shopping_materials?material_id=${material}`;
        console.log("materialsUrl: ", materialsUrl);
        fetch(materialsUrl)
        .then(response => response.json())
        .then(data => setMaterialName(data))
        .catch(error => console.log(error));
    }
    }, [material]);


    const [successMessage, setSuccessMessage] = useState('');

    const handleAddToCart = () => {
        const selectElement = document.getElementById('quantity');
        const quantity = selectElement.value;

        const newItem = {
            id: id,
            quantity: quantity,
            category: 'ecommerce',
            price: price
        };

        // Retrieve existing cart items from local storage or initialize an empty array
        const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        // Add the new item to the existing array of cart items
        const updatedItems = [...existingItems, newItem];

        // Store the updated array back into local storage
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));

        setSuccessMessage('Item successfully added to cart.');
    };

    const handleBuyNow = () => {
        const selectElement = document.getElementById('quantity');
        const quantity = selectElement.value;

        const newItem = {
            id: id,
            quantity: quantity,
            category: 'ecommerce',
            price: price
        };

        // Retrieve existing cart items from local storage or initialize an empty array
        const existingItems = JSON.parse(localStorage.getItem('cartItems')) || [];

        // Add the new item to the existing array of cart items
        const updatedItems = [...existingItems, newItem];

        // Store the updated array back into local storage
        localStorage.setItem('cartItems', JSON.stringify(updatedItems));

        window.location.href = "/cart";
    };

  return (
    <div>
        {/* Slider section */}
        <section className="z-5">
            <div className="swiper-wrapper">
                <Swiper
                spaceBetween={30}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                slidesPerView={1}
                loop={true}
                modules={[Autoplay]}
                className="mySwiper"
                >
                {swiperData.map((item) => (
                    <SwiperSlide key={item.id}>
                    <img style={{width:'100%'}} src={
                      item.image.includes('aim_')
                      ? `https://blessingsonthenet.com/img/slideshow/${item.image}`
                      : `https://api.blessingsonthenet.com/uploads/microsite/${item.image}`
                      } alt={`Slide ${item.id}`} />
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
        </section>

      <section class="py-10 d-flex items-center bg-light-2">
        <div class="container">
          <div class="row y-gap-10 items-center justify-between">
            <div class="col-auto">
              <div class="row x-gap-10 y-gap-5 items-center text-14 text-light-1">
                <div class="col-auto">
                  <div class="">Home</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="">Products</div>
                </div>
                <div class="col-auto">
                  <div class="">&gt;</div>
                </div>
                <div class="col-auto">
                  <div class="text-dark-1">{product_name}</div>
                </div>
              </div>
            </div>

            <div class="col-auto">
              {/*<a href="#" class="text-14 text-blue-1 underline">View Products Involving Dwarka</a>*/}
            </div>
          </div>
        </div>
      </section>


      <section class="pt-40">
      <div class="container">
        <div class="row y-gap-30">
            <div class="col-lg-12">
                <div class="row y-gap-20 justify-between items-end">
                    <div class="col-auto">
                        <h1 class="text-30 sm:text-24 fw-600">{product_name}</h1>

                        <div class="row x-gap-10 items-center pt-10">
                        <div class="col-auto">
                            <div class="d-flex x-gap-5 items-center">
                            <div class="text-15 text-light-1"><b>Category:</b> {category_name}{sku ? (', Product ID: '+sku) : ''}</div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
          <div class="col-lg-6">
            <ImageGallery items={images} slideInterval={6000} thumbnailPosition="left" />
          </div>

          <div class="col-lg-6">
            {short_description?.length > 200 ? 
            (<div class="d-flex justify-end" style={{height:300, overflowY:'scroll'}} dangerouslySetInnerHTML={{ __html: `${short_description}` }}></div>) : 
            (<div class="d-flex justify-end" style={{height:300}} dangerouslySetInnerHTML={{ __html: `${short_description}` }}></div>)
            }

            <div class="col-auto border-top-light mt-20">
              <div class="row x-gap-15 y-gap-15 items-center">
                <div class="col-auto py-30">
                  <div class="text-14">
                    <span className="text-30 text-dark-1 fw-500">₹{price}</span>
                  </div>
                </div>
                <div className="col-auto py-30" style={{color:'red'}}>
                    <h3>Coming Soon</h3>
                </div>
                {/*
                <div className="col-auto py-30">
                    <a href={`https://www.blessingsonthenet.com/product/${product_name ? product_name.toLowerCase().replace(/\s+/g, '-') : ''}/${id}`}>
                        <button className="button h-50 px-24 -blue-1 bg-dark-3 text-white">
                            Buy Now <img src="/assets/img/general/lang.png" alt="image" style={{ marginLeft: 8, width: '18px', marginTop: '-2px' }}></img>
                        </button>
                    </a>
                </div>
                */}

              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="layout-pt-lg mt-50 layout-pb-lg">
        <div class="container border-top-light pt-30">
          <div class="row justify-center text-center">
            <div class="col-auto">
              <div class="sectionTitle -md">
                <h2 class="sectionTitle__title">Other Products that might interest you</h2>
                <p class=" sectionTitle__text mt-5 sm:mt-0">Some of the exciting products that are similar</p>
              </div>
            </div>
          </div>

          <div class="row y-gap-30 pt-40 sm:pt-20">

            {similarProducts.map(list => (
                <div class="col-xl-3 col-lg-3 col-sm-6 mt-10">

                <a href={`/product/${list.product_name.toLowerCase().replace(/\s+/g, '-')}/${list.id}`} class="hotelsCard -type-1">

                <div class="hotelsCard__image">

                    <div class="cardImage ratio ratio-1:1">
                    <div class="cardImage__content">

                        <img class="rounded-4 col-12" src={`${process.env.REACT_APP_API_ENDPOINT}uploads/shopping/${list.image_src}`} alt="image" />

                    </div>

                    </div>

                </div>

                <div class="hotelsCard__content mt-10 text-center">
                    <h4 class="hotelsCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>{list.product_name}
                        </span>
                    </h4>

                    {/*<p class="text-light-1 lh-14 text-14 mt-5">{list.category_name}</p>*/}

                    <div>
                        <div class="fw-500">
                            {list.price > 0 ?
                            <span dangerouslySetInnerHTML={{ __html: `<span class="text-blue-1">₹${list.price}</span>` }}></span> : 'Contact us for Price'
                            }
                        </div>
                    </div>
                </div>
                </a>

            </div>
            ))}

          </div>
        </div>
      </section>


      <SubscribeRibbon />
    </div>
  );
}

export default Product;
